<template>
	<div id="page" :style="bg ? `background-image: url('${bg}');` : ''">
		<div class="content" v-if="prizes.length>0">
			<van-row style="height:100%">
				<van-col offset="2" span="20" style="height:100%">
					<div class="content-label">
						<van-row style="height:100%">
							<van-col span="1" style="height:100%">

								<ul class="luckdraw_level_btn">
									<li v-for="(prize,index) in prizes" :index="index" :key="prize" 
										@click="setCurrentprize(index)"
										class="btn" :class="index == current ? 'btn_on' :'' "
										>
										{{index+1}}
									</li>
								</ul>
							</van-col>
							<van-col offset="1" span="10" style="height:100%">
								<div class="prize">
									<van-row style="height:100%">
										<van-col offset="6" span="10" style="height:100%">
											<template v-for="(prize,index) in prizes" :key="prize" :index="index">
												<transition name="van-slide-left">
													<img v-show="current == index" class="prizeImg" :src="prize.image" />
												</transition>
											</template>
										</van-col>
									</van-row>
								</div>
							</van-col>
							<van-col offset="1" span="10" style="height:100%">
								
									<template v-for="(prize,index) in prizes" :key="prize" :index="index">
										<transition name="van-fade">
										<ul class="titles" v-show="current == index" >
											<li class="title_en">{{prize.en_title}} {{prize.quota}} people</li>
											<li class="title_en">{{prize.en_prize_name}}</li>
											<li class="title_ch">{{prize.cn_title}} {{prize.quota}}名</li>
											<li class="title_ch">{{prize.cn_prize_name}}</li>
										</ul>
										</transition>
									</template>
								
								<div class="set_prize_num">
									<van-row >
										<van-col span="6" style="height:100%">
											<img @click="start" class="start" src="../assets/luckdraw_start.png" />
										</van-col>
										<van-col span="3" style="height:100%" v-show="allow()">
											<div class="quantity">Quantity</div>
											<div class="quantity">(剩余{{prizes[current].quota - prizes[current].complete}})数量</div>
										</van-col>
										<van-col span="5" style="height:100%" v-show="allow()">
											<img @click="reduce" class="num_btn left_btn" src="../assets/luckdraw_reduce.png"/>
											<div class="num center_btn">{{number}}</div>
											<img @click="add" class="num_btn right_btn" src="../assets/luckdraw_add.png"/>
										</van-col>
									</van-row>
									<van-row style="width:100%;">
										<van-col span="8" >
											<div @click="luckDrawUserList" class="quantity" style="text-align:left;" >
												<div>查看中奖名单 </div>
												<div>View the list of winners </div>
											</div>
										</van-col>
									</van-row>
								</div>
							</van-col>
						</van-row>
					</div>
				</van-col>
			</van-row>
		</div>
		<template v-if="menuItems">
		<toggle-menu
			position="RB"
			:menuItems="menuItems"
            @clickMenu="clickMenu"
			menuBg="#2A3E53"
			itemBg="#2A3E53"
        />
		</template>
	</div>
</template>

<script>
import { reactive, toRefs , getCurrentInstance,onMounted} from 'vue';
import { useRouter } from 'vue-router';
import toggleMenu from '../components/toggleMenu';
import { get } from '../library/serviceUtil';
import Menu from '../library/Menu';
import Common from '../library/Common';
import '../css/font.css';

export default {
	name: 'luckdraw',
	components: {
		toggleMenu
	},
    setup(){
		const router = useRouter();
		const common = new Common();
		const menu = new Menu("luckDraw",router,common);
		const { proxy } = getCurrentInstance();
		const index = proxy.$root.$route.params.index;
		let activity = null;

		let state = reactive({
			menuItems:null,
			number:1,
			program_id:null,
			current:0,
			bg:null,
			prizes:[],
		});
		
		const clickMenu = function(item){
			menu.click(item);
		}

		const setCurrentprize = function(index){
			state.current = index;
			state.number = 1;
		}

		const start = function(){
			if(!allow()) return ;
			menu.stopMusic();
			router.push({
				name: 'runLuckDraw',
				params:{
					program_id:activity.lottery.id,
					index:state.prizes[state.current].index,
					lottery_index:state.current,
					number:state.number,
					bg:state.bg,
					bg_music:state.bg_music,
				}
			});
		}

		const luckDrawUserList = function(){
			menu.stopMusic();
			router.push({
				name: 'luckDrawUserList',
				params:{
					program_id:activity.lottery.id,
					index:state.current,
					bg:state.bg,
					bg_music:state.bg_music,
				}
			});
		}

		const reduce = function(){
			if(state.number==1) return ;
			state.number--;
		}

		const allow = function(){
			return state.prizes[state.current].quota != state.prizes[state.current].complete;
		}

		const add = function(){
			var max = state.prizes[state.current].quota - state.prizes[state.current].complete;
			if(state.number == max){
				return ;
			}
			state.number++;
		}

		const getPrize = async()=>{
			var program_id = activity.lottery.id;
			var res = await get(`/activity/program/${program_id}/`);
			state.bg = res.data.banner;
			state.bg_music = res.data.bg_music;
			menu.setMusic(res.data.bg_music);
			state.prizes = res.data.lottery;
		}

		onMounted(async()=>{
				await menu.init();
				activity = common.getLocal("activity");
				state.menuItems = menu.menuItems;
				state.program_id = activity.lottery.id;
				getPrize();
			if(index){
				setCurrentprize(index);
			}
		})

		return {
			...toRefs(state),
			common,
			clickMenu,
			reduce,
			add,
			setCurrentprize,
			start,
			allow,
			luckDrawUserList
		};
	}
}
</script>
<style scoped>
#page{
	width: 100%;
	height: 100%;
	background-color: #fff;
	background-image: url('../assets/bg.png');
	background-repeat: no-repeat;
    background-size: 100% 100%;
	cursor:default;
}
.content{
	position:absolute;
	top:15%;
	height:70%;
	width:100%;
	margin:auto;
}

.content-label{
	width:100%;
	height:100%;
	background-image: url('../assets/luckdraw_layer.png');
	background-repeat: no-repeat;
    background-size: 100% 100%;
}

.luckdraw_level_btn{
	position:absolute;
	top:2%;
	margin-left:1.5%;
	padding-right: 5px;
	height: 95%;
	overflow-x: hidden;
	overflow-y: auto;
}


.luckdraw_level_btn::-webkit-scrollbar {/*滚动条整体样式*/
	width: 6px;     /*高宽分别对应横竖滚动条的尺寸*/
	height: 1px;
}

.luckdraw_level_btn::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
	background: #D3AC85;
}
.luckdraw_level_btn::-webkit-scrollbar-track {/*滚动条里面轨道*/
	-webkit-box-shadow: inset 0 0 0.1rem rgba(0,0,0,0.2);
	border-radius: 10px;
	background: rgba(5,5,5,0.2);
}

.luckdraw_level_btn li{
	margin-bottom:0.08rem;
}
.btn{
	width:0.2rem;
	height:0.2rem;
	line-height: 0.2rem;
	color:#fff;
	font-size:0.05rem;
	font-family: 'youshe';
	text-align: center;
	background-image: url('../assets/luckdraw_level_btn.png');
	background-repeat: no-repeat;
    background-size: 100% 100%;
}
.btn_on{
	color:#001F3C;
	font-family: 'youshe';
	background-image: url('../assets/luckdraw_level_btn_on.png');
}
.prizeImg{
	position:absolute;
	width:18%;
	bottom:35%;
}

.prize{
	height:100%;
	width:100%;
	background-image: url('../assets/prizetray.png');
	background-repeat: no-repeat;
    background-size: 100% auto;
	background-position: bottom;
}

.titles,.titles_en{
	position:absolute;
	top:18%;
	font-size:0.25rem;
	color:#D3AC85;
}
.title_ch{
	font-family: 'youshe';
}

.title_en{
	font-family: 'Biotif';
}

.set_prize_num{
	position:absolute;
	top:68%;
}

.start{
	width:100%; 
}

.quantity{
	color:#B3B4B6;
	width:100%; 
	text-align: right;
	margin-top:5%;
	font-weight: 600;
	font-size: 0.08rem;
}


.num{
	position:absolute;
	top:5%;
	font-size:0.1rem;
	text-align: center;
	color: #fff;
	width:0.25rem;
	line-height:0.25rem;
	height:0.25rem;
	background-image: url('../assets/luckdraw_count.png');
	background-repeat: no-repeat;
    background-size: 100% auto;
}
.num_btn{
	position:absolute;
	top:0.08rem;
	width:0.15rem;
	z-index: 5;
}
.left_btn{
	margin-left:0.1rem;
	
}
.center_btn{
	margin-left:0.22rem;
}
.right_btn{
	margin-left:0.45rem;
}
</style>